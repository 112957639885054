/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'account-dropdown': {
    width: 42,
    height: 25,
    viewBox: '0 0 42 25',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _stroke="#F9F4EC" stroke-width="1.5" stroke-linecap="square" d="M13.922 6.5h-11m11 11h-11m11-5.497H0"/><path pid="1" d="M32.227 21.11l2.679 2.386c.145.13.16.356.035.506a.345.345 0 01-.264.123h-5.322a.354.354 0 01-.349-.358c0-.113.052-.22.14-.287l3.08-2.37zM32 0c5.901 0 10.321 4.893 9.982 10.776-.21 3.638-3.09 7.232-8.576 11.382-.01.01-.016.016-.017.014a84.91 84.91 0 01-1.162-1.062c5.324-3.995 8.041-7.298 8.221-10.425.289-5-3.441-9.13-8.448-9.13-5.007 0-8.737 4.13-8.448 9.13.164 2.851 2.132 5.165 6.587 8.763l-1.337.906c-4.516-3.73-6.593-6.28-6.784-9.578C21.678 4.893 26.098 0 32 0zm.184 3.267c3.69 0 6.626 3.021 6.626 6.66h-1.541c0-2.791-2.254-5.111-5.085-5.111V3.267z" _fill="#F9F4EC" fill-rule="nonzero"/></g>'
  }
})
